<template>
    <div id="success">
        <img src="https://khome2.tuzuu.com/vita/ahome_1.png">
        <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">
        <div class="content">
            <div class="cell flex">
                <img src="https://khome2.tuzuu.com/vita/icon_6.png">
                支付成功
            </div>
            <div class="cell2">
                您将获得以下健康权益
            </div>
            <div class="sub-title">
                定制检测服务
            </div>
            <div class="info">
                通过遗传基因、基因分析、营养成分，寻找最适合你的健康方案
            </div>

            <div class="sub-title">
                营养顾问1对1服务
            </div>
            <div class="info">
                有什么问题，来找我们，让我们经验丰富的营养师为您解忧
            </div>

            <div class="sub-title">
                360天定制营养包<span>/ 保持新鲜 分阶段直运</span>
            </div>
            <div class="info">
                海外直运，每天一小包，让健康简简单单
            </div>

        </div>

        <div class="end-info">
            我们将为您邮寄定制营养包，请您完善收货信息
        </div>

        <div class="btn flex-center" @click="href">
            <van-icon name="add-o"  color="#6B00AF" size="20" style="margin-right: 8px"/>
            添加收货地址
        </div>

    </div>
</template>

<script>
    export default {
        name: "",
        methods:{
            href(){
                let order_sn = this.$route.params.sn
                this.$router.push({
                    path:`/product/address/${order_sn}`
                })
            }
        }
    }
</script>

<style scoped lang="less">
    #success{
        width: 100%;
        min-height: 100vh;
        background-color: #F2F2F2;
        font-size: 16px;
        position: relative;
        padding-bottom: 30px;
        box-sizing: border-box;
    }
    .content{
        width: calc(100% - 20px);

        margin: 0 auto;
        background-color: white;
        margin-top: -100px;
        position: relative;
        border-radius: 10px;
        padding: 20px 22px;
        padding-bottom: 35px;
        box-sizing: border-box;
        .cell{
            font-size: 14px;
            margin-bottom: 15px;
            font-weight:700;
            img{
                width: 22px;
                margin-right: 7px;
            }
        }
        .cell2{
            font-size: 14px;
            color: #292C2F;

            margin-bottom: 25px;
        }
        .sub-title{
            font-size: 16px;
            color: #292C2F;
            font-weight: 900;
            border-bottom: 1px solid #292C2F;
            padding-bottom: 5px;
            margin-bottom: 4px;
            margin-top: 30px;
            span{
                font-size: 12px;
                color: #666666;
                margin-left: 4px;
                font-weight: 400;
            }
        }
        .info{
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            letter-spacing: 0.5px;
            font-family: HYQiHei-EES, HYQiHei;
        }
    }

    .end-info{
        font-size: 14px;
        color: #3F3F3F;
        margin-top: 25px;
        margin-bottom: 10px;
        text-align: center;

    }
    .btn{
        width: calc(100% - 20px);
        margin: 0 auto;
        background-color: white;
        padding: 13px 0px;
        border-radius: 10px;
        font-weight: 700;
        color: #6B00AF;
    }
</style>